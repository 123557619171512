<template>
  <section id="about" class="section is-medium">
    <div class="columns is-centered">

      <div class="column"></div>
      <div class="column is-three-fifths">

        <h1 class="title my-6">From product design to<br/>software continuous delivery</h1>

        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <div class="is-flex is-horizontal-center">
                <figure class="image is-64x64 is-centered">
                  <img src="~@/assets/images/coding.svg">
                </figure>
              </div>
              <h2>Web Development</h2>
              <p>High performance web-applications that grow with your business</p>
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child box">
              <div class="is-flex is-horizontal-center">
                <figure class="image is-64x64 is-centered">
                  <img src="~@/assets/images/cloud-network.svg">
                </figure>
              </div>
              <h2>Cloud &amp; DevOps</h2>
              <p>Deploying and maintaining a scalable and secure architecture based on Google cloud solutions</p>
            </article>
          </div>
        </div>

        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <div class="is-flex is-horizontal-center">
                <figure class="image is-64x64 is-centered">
                  <img src="~@/assets/images/3d-modeling.svg">
                </figure>
              </div>
              <h2>Software Architecture</h2>
              <p>Adaptable software architecture development centered on scalability, performant and cost effective</p>
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child box">
              <div class="is-flex is-horizontal-center">
                <figure class="image is-64x64 is-centered">
                  <img src="~@/assets/images/design-process.svg">
                </figure>
              </div>
              <h2>Digital Product Design</h2>
              <p>Complete digital product creations from UX prototyping to final UI designs</p>
            </article>
          </div>
        </div>

      </div>
      <div class="column"></div>

    </div>
  </section>
</template>

<script>
export default {
  name: "About",
}
</script>

<style scoped>
h1 {
  font-weight: 100;
}

h2 {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 0.3em;
}

.is-horizontal-center {
  justify-content: center;
}
</style>