<template>
  <section id="contact" class="section">
    <div class="columns is-centered has-text-white">
      <div class="column is-half">
        <div class="mb-4">
          <h2 class="collab title has-text-white">Ready to collaborate?</h2>
        </div>
        <div class="mb-4 is-size-7">
          <h2>Email</h2>
          <p>
            hello@ctech.id
          </p>
        </div>
        <div class="container">
          <a href="mailto:hello@ctech.id">
            <b-button rounded outlined>
              Contact Us
            </b-button>
          </a>
        </div>
      </div>
    </div>
    <footer>
      <div class="content has-text-centered is-size-7 has-text-white">
        <p>
          &copy; CTech Indonesia | 2020
        </p>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>
h2 {
  font-weight: bold !important;
}

h2.collab {
  font-weight: 100 !important;
}

section {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.6), 
      rgba(0, 0, 0, 0.9)
    ), 
    url("~@/assets/images/appai--UTi6eeBpFw-unsplash.jpg");
  background-size: cover;
}

footer {
  padding: 0px !important;
}
</style>