<template>
  <div id="app">
    <HeroSection/>
    <AboutSection/>
    <!-- <WorksSection/> -->
    <ContactSection/>
  </div>
</template>

<script>
import HeroSection from '@/components/index/HeroSection.vue'
import AboutSection from '@/components/index/AboutSection.vue'
// import WorksSection from '@/components/index/WorksSection.vue'
import ContactSection from '@/components/index/ContactSection.vue'

export default {
  name: 'App',
  title: 'CTech - Delivering high quality web apps.',
  components: {
    HeroSection,
    AboutSection,
    // WorksSection,
    ContactSection
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;400;600&display=swap');

body {
  font-family: 'Barlow', Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
