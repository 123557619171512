<template>
  <section class="hero is-dark is-fullheight">
    <div class="hero-head">
      <Navbar/>
    </div>
    <div class="hero-body">
      <div class="container has-text-center">
        <p class="title is-size-1">
          <vue-typer
            :text='["Creative","Collaborative","Cooperative","Clean"]'
            :repeat='Infinity'
            :shuffle='true'
            initial-action='erasing'
            :pre-type-delay='70'
            :type-delay='70'
            :pre-erase-delay='2000'
            :erase-delay='70'
            erase-style='backspace'
            :erase-on-complete='true'
            caret-animation='phase'
          ></vue-typer> Tech
        </p>
        <p class="subtitle is-size-6">
          Delivering high-quality web apps.<br/>
          We're here to help your needs
        </p>
      </div>
    </div>
    <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="4"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
    </vue-particles>
  </section>
</template>

<script>
import { VueTyper } from 'vue-typer'
import Navbar from '@/components/Navbar.vue'

export default {
  name: "HeroSection",
  components: {
    VueTyper,
    Navbar
  }
}
</script>

<style scoped>

section {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.95), 
      rgba(0, 0, 0, 0.7)
    ),
    url("~@/assets/images/rupixen-com-Ai6sfNRvlvc-unsplash.jpg");
  background-size: cover;
  background-position-y: center;
}

#particles-js {
  position: absolute;
  height: 100vh;
}

</style>