<template>
  <b-navbar
    :transparent="true"
  >
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="@/assets/ctech-logo-final-white.svg"
          alt="CTech logo"
        >
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item href="#about">
        About
      </b-navbar-item>
      <b-navbar-item href="#works">
        Works
      </b-navbar-item>
      <b-navbar-item href="#contact">
        Contact
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
}
</script>

<style scoped>

</style>